@import '../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  height: 100%;
  padding: 0 24px;

  @media (--viewportLarge) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
}

.searchBoxWrapper {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: var(--colorWhite);
  z-index: 10;
  padding: 20px;
  padding-top: 60px;
  position: fixed;
}

.searchMobileFieldWrapper {
  position: relative;
}

.searchMobileFieldIcon {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  pointer-events: none;
  height: 100%;
  width: 25px;
}

.searchMobileField {
  width: 100%;
  padding: 15px 15px 15px 60px;
  border: 1px solid #ccc;
  border-radius: 50px;
  font-size: 16px;
}

.searchBoxMobileCloseIcon {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 11;
  font-size: 24px;
}

.searchBox {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  z-index: 11;
  row-gap: 20px;
  @media (--viewportMedium) {
    z-index: 1;
  }
  @media (--viewportLarge) {
    flex-direction: row;
    padding: 18px 22px;
    border-radius: 604px;
    border: 1px solid #e5e5e6;
    box-shadow: 0px 2px 5px 0px #0000001a;
    row-gap: 0px;
  }
}

.searchBoxItem {
  width: 100%;
  border-bottom: 1px solid var(--colorGrey100);
  display: flex;
  align-items: center;
  /* position: relative; */
  border: 1px solid var(--colorGrey100);
  border-radius: 25px;

  @media (--viewportLarge) {
    display: block;
    height: 44px;
    border: none;
    border-radius: 0px;
    border-bottom: 0px solid transparent;

    &:nth-child(2) {
      border-left: 1px solid var(--colorGrey100);
      border-right: 1px solid var(--colorGrey100);
    }

    &:nth-child(3) {
      border-right: 1px solid var(--colorGrey100);
    }

    &:nth-child(1) {
      width: 135px;
    }

    &:nth-child(2) {
      width: 120px;
    }

    &:nth-child(3) {
      width: 120px;
    }

    &:nth-child(4) {
      width: 140px;
    }
  }

  @media (--viewportLarge) {
    &:nth-child(1) {
      width: 177px;
    }

    &:nth-child(2) {
      width: 141px;
    }

    &:nth-child(3) {
      width: 141px;
    }

    &:nth-child(4) {
      width: 231px;
    }
  }
}

.searchBoxItemOutsideClick {
  width: 100%;
}

.searchBoxItemContent {
  position: relative;
  padding: 12px 25px;
  /* cursor: default; */
  transition: background-color 0.2s linear;
  height: 100%;
  width: 100%;

  & svg {
    margin-left: 10px;
    width: 20px;
  }

  & label {
    font-size: 12px;
    line-height: 16px;
    padding: 0 0 5px;
  }

  @media (--viewportLarge) {
    padding: 0 20px;
  }
}

.searchBoxItemInput {
  font-size: 14px;
  font-weight: 600;
  color: var(--colorBlack);
  height: 25px;
  overflow: hidden;
  white-space: nowrap;

  @media (--viewportMedium) {
    font-size: 16px;
  }
}

.input {
  padding: 0;
  line-height: 25px;
  border: none;
  font-size: 14px;
  font-weight: 600;
  color: var(--colorBlack);

  &:focus,
  &:hover {
    outline: none;
    border: 0px solid transparent;
    box-shadow: none;
  }

  @media (--viewportMedium) {
    font-size: 16px;
  }

  &:disabled {
    opacity: 1 !important;
  }

  &::placeholder {
    font-size: 14px;
    font-weight: 600;
    color: var(--colorGrey300);

    @media (--viewportMedium) {
      font-size: 16px;
    }
  }
}

.dateInput {
  cursor: pointer !important;
}

.dateCancelIcon {
  cursor: pointer !important;
}

.placeholder {
  color: var(--colorGrey300);
}

.suggestionPanel {
  position: absolute;
  top: 55px;
  right: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  border-radius: 10px;
  overflow: hidden;
  z-index: 100;

  & ul {
    margin: 0;

    & li {
      padding: 10px 20px;
      cursor: pointer;
      transition: all 0.2 linear;

      &:hover {
        background-color: var(--colorGrey800);
      }
    }
  }
}

.locationAutocompletePanel {
  height: 24px;
  flex-grow: 1;
}

.locationAutocomplete {
  height: 24px;
}

.locationAutocompleteIcon {
  display: none;
}

.locationAutocompleteInput {
  background-color: transparent;
  border-radius: 0;
  border: 0px solid transparent;
  border-bottom: 0px solid transparent;
  outline: none;
  padding: 0;
  width: 100%;
  font-size: 14px;
  font-weight: 600;

  @media (--viewportMedium) {
    font-size: 16px;
  }

  &:hover {
    border: 0px solid transparent;
    border-bottom: 0px solid transparent;
  }

  &:focus {
    background-color: transparent;
    border-bottom: 0px solid transparent;
    outline: none;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &::placeholder {
    font-size: 14px;
    font-weight: 600;
    color: var(--colorGrey300);

    @media (--viewportMedium) {
      font-size: 16px;
    }
  }
}

.locationAutocompletePredict {
  position: absolute;
  top: 37px;
  left: -25px;
  right: -25px;
  background-color: var(--colorWhite);
  border-radius: 25px;
  overflow: hidden;
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportLarge) {
    top: 52px;
    left: -20px;
    right: -160px;
  }
}

.searchSubmit {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: unset;
  height: 38px;
  width: 100%;
  font-size: 15px;
  margin-bottom: 20px;
  width: auto;
  padding: 0px 20px;
  column-gap: 15px;
  display: flex;
  align-items: center;
  margin-left: auto;
  position: fixed;
  bottom: -2px;
  right: 24px;
  height: 48px;
  min-height: 48px;
  flex-direction: row-reverse;

  & > span {
    margin-right: 12px;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    position: static;
    width: 120px;
    height: 44px;
    border-radius: 104px;
    justify-content: center;
    align-items: center;
    min-height: unset;
    font-size: 15px;
    margin-bottom: 0px;
    padding: 0px;
    column-gap: 0px;
  }
}

.selectDatePanel {
  position: absolute;
  top: 141px;
  left: 50% !important;
  transform: translateX(-50%);
  right: 0;
  border-radius: 25px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowPopup);
  overflow: hidden;
  z-index: 100;
  width: calc(100% + 4px);

  @media (--viewportLarge) {
    left: calc(50%) !important;
    top: 91px;
    right: unset;
    width: 100%;
  }

  & :global(.CalendarDay:hover .renderedDay) {
    /* background-color: var(--colorGrey100) !important; */
  }

  max-height: 400px;
  height: 400px;

  /* & :global(.DateRangeController_inputRoot__L-LGZ){
    height: 100%;
  } */

  & :global(div) {
    &:last-of-type {
      height: 100%;
    }
  }

  & :global(.CalendarMonthGrid) {
    overflow-y: hidden;
  }

  & :global(.CalendarDay:hover .renderedDay) {
    border-radius: 50%;
  }

  & :global(.CalendarMonth_caption) {
    padding-top: 14px;
    padding-bottom: 20px;
  }

  & :global(.DayPicker_weekHeader) {
    padding: 0px 25px 0px 0px;
  }

  & :global(.DayPickerNavigation_button) {
    display: none;
  }

  & :global(.DayPicker_weekHeader) {
    &:last-of-type {
      top: -1px;
      & :global(.DayPicker_weekHeader_ul) {
        background-color: white;
      }
    }
  }

  /* & :global(.CalendarDay__selected_end::after) {
    top: 0px;
  }
  & :global(.CalendarDay__selected_start::after) {
    top: 2.5%;
  }

  @media (--viewportMedium) {
    & :global(.CalendarDay__selected_start::after) {
      top: 0%;
    }
  } */

  & :global(.CalendarDay__hovered_span),
  & :global(.CalendarDay__selected_span),
  & :global(.CalendarDay__hovered_span:hover),
  & :global(.CalendarDay__selected_span:hover) {
    border: none !important;
  }
  & :global(.CalendarDay__hovered),
  & :global(.CalendarDay__selected),
  & :global(.CalendarDay__hovered:hover),
  & :global(.CalendarDay__selected:hover) {
    border: none !important;
  }

  @media (min-width: 920px) {
    & :global(.DayPicker_focusRegion) {
      width: 850px !important;
    }

    & :global(.DayPicker_transitionContainer) {
      width: 850px !important;
      height: 390px !important;
    }

    & :global(.CalendarMonthGrid) {
      background-color: transparent;
      height: 400px;
      width: auto;
    }

    & :global(div[data-testid='DateRangePicker']) {
      width: 100% !important; /* Adjust the width as needed */
    }

    & :global(.DayPicker__horizontal) {
      margin: 0 auto;
      box-shadow: none;
      background-color: transparent;
      width: 100% !important;
    }

    & :global(.DayPickerNavigation__horizontal) {
      position: relative;
      width: 100%;
    }

    & :global(.DayPickerNavigation__bottom) {
      display: none;
      &:first-of-type {
        display: block;
      }
    }

    & :global(.DayPickerNavigation_button__horizontal) {
      padding: 6px 9px;
      top: 21px;
      position: absolute;
      cursor: pointer;
      display: inline;
      &:first-of-type {
        left: 24px;
      }
      &:last-of-type {
        right: 24px;
      }

      @media (--viewportMedium) {
        top: 18px;
      }
    }
    & :global(.DayPickerNavigation_button) {
      color: var(--colorBlack);
      border: 0;
    }
    & :global(.CalendarMonth) {
      padding: 0px 42px !important;
    }
    & :global(.CalendarMonth),
    & :global(.CalendarMonthGrid) {
      background-color: transparent;
    }

    & :global(.DayPicker_weekHeader) {
      color: var(--colorBlack);
      top: 62px;
      padding: 0px 42px !important;
      &:last-of-type {
        left: 50% !important;
        transform: translate(29%) !important;
        width: 425px !important;
        top: 62px;
        & :global(.DayPicker_weekHeader_ul) {
          background-color: transparent;
        }
      }
    }

    & :global(.DayPicker_weekHeader_li) {
      font-weight: var(--fontWeightMedium);
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0;

      margin-top: 0;
      margin-bottom: 0;

      @media (--viewportMedium) {
        width: 48px !important;
        height: 48px !important;
        line-height: 16px;
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    & :global(.DayPicker_weekHeader_li small) {
      /* font-size: 100%; */
      font-size: 16px !important;
    }

    & :global(.CalendarMonth_caption) {
      color: var(--colorBlack);
      font-weight: var(--fontWeightRegular);
      /* font-size: 18px; */
      line-height: 20px;
      font-size: 20px !important;

      margin: 1px 0 14px;
      padding-top: 28px;
      padding-bottom: 40px;

      &::first-letter {
        text-transform: capitalize;
      }

      @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    & :global(.CalendarDay__default) {
      border: 0;
      padding: 0px;
      width: 48px !important;
      height: 48px !important;
      /* padding: 1px 0px !important; */

      background: transparent;
    }

    & :global(.CalendarDay) {
      font-weight: var(--fontWeightMedium);
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0;

      color: var(--colorBlack);
      border: 0;
      margin-top: 0;
      margin-bottom: 0;

      @media (--viewportMedium) {
        font-weight: var(--fontWeightMedium);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    /* Add an underline for '.renderedDay' */
    & :global(.CalendarDay__today .renderedDay) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      /* height: var(--DateRangeController_selectionHeight); */
      background-image: url("data:image/svg+xml;utf8,<svg width='14' height='2' viewBox='0 0 14 2' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h14v2H0z' fill='%234A4A4A' fill-rule='evenodd'/></svg>");
      background-position: center 28px;
      width: 100% !important;
      height: 100% !important;
      border-radius: 100% !important;
    }

    & :global(.CalendarDay__today.CalendarDay__selected .renderedDay) {
      background-image: url("data:image/svg+xml;utf8,<svg width='14' height='2' viewBox='0 0 14 2' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h14v2H0z' fill='%23FFF' fill-rule='evenodd'/></svg>");
    }

    & :global(.CalendarDay:hover .renderedDay) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: var(--DateRangeController_selectionHeight);
      /* background-color: var(--colorGrey100); */
      color: var(--colorGrey700);
      width: 100% !important;
      height: 100% !important;
      border-radius: 100% !important;
    }

    /* Remove default bg-color and use our extra span instead '.renderedDay' */
    & :global(.CalendarDay__hovered_span:hover .renderedDay) {
      background-color: var(--colorBlack) !important;
      color: var(--colorWhite) !important;
    }
    & :global(.CalendarDay__selected_span) {
      background-color: rgb(247, 247, 247);
      border-radius: 0px;
    }
    & :global(.CalendarDay__hovered_span:hover) {
      background-color: rgb(247, 247, 247) !important;
      border-radius: 0px;
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }
    & :global(.CalendarDay__hovered_span .renderedDay),
    & :global(.CalendarDay__selected_span .renderedDay),
    & :global(.CalendarDay__hovered_span:hover .renderedDay),
    & :global(.CalendarDay__selected_span:hover .renderedDay) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      background-color: rgb(247, 247, 247);
      color: var(--colorBlack);
      width: 100% !important;
      height: 100% !important;
    }

    & :global(.CalendarDay__hovered_offset) {
      background-color: var(--colorGrey100);
    }

    /* Remove default bg-color and use our extra span instead '.renderedDay' */
    & :global(.CalendarDay__selected_start) {
      position: relative;
      background-color: rgb(247, 247, 247);
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }

    /* & :global(.CalendarDay__selected_start::before) {
      content: '';
      position: absolute;
      top: 0%;
      left: 0;
      width: 100%;
      height: 2.5%;
      background-color: white;
    }

    & :global(.CalendarDay__selected_start::after) {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2.5%;
      background-color: white;
    } */

    & :global(.CalendarDay__selected_start:hover) {
      position: relative;
      background-color: rgb(247, 247, 247);
      border-radius: 0px;
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }

    /* & :global(.CalendarDay__selected_start::after) {
      content: '';
      position: absolute;
      top: 1px;
      right: 0;
      width: 50%;
      height: calc(100% - 2px);
      z-index: 0;
      background-color: rgb(247, 247, 247);
    } */

    & :global(.CalendarDay__selected_start .renderedDay) {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      z-index: 1;
      background-color: rgb(34, 34, 34);
      border-radius: 50%;
      color: var(--colorWhite);
      width: 100% !important;
      height: 100% !important;
      border-radius: 100% !important;
    }

    /* Remove default bg-color and use our extra span instead '.renderedDay' */
    & :global(.CalendarDay__selected_end) {
      background-color: rgb(247, 247, 247);
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
      position: relative;
    }

    & :global(.CalendarDay__selected_end:hover) {
      position: relative;
      background-color: rgb(247, 247, 247);
      border-radius: 0px;
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }

    /* & :global(.CalendarDay__selected_end::before) {
      content: '';
      position: absolute;
      top: 0%;
      left: 0;
      width: 100%;
      height: 2.5%;
      background-color: white;
    }

    & :global(.CalendarDay__selected_end::after) {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2.5%;
      background-color: white;
    } */

    /*& :global(.CalendarDay__selected_end::after) {
      content: '';
      position: absolute;
      top: 1px;
      left: 0;
      width: 50%;
      height: calc(100% - 2px);
      z-index: 0;
      background-color: rgb(247, 247, 247);
    } */

    & :global(.CalendarDay__selected_end .renderedDay) {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      background-color: rgb(34, 34, 34);
      border-radius: 50%;
      z-index: 1;
      color: var(--colorWhite);
      width: 100% !important;
      height: 100% !important;
      border-radius: 100% !important;
    }
    & :global(.CalendarDay:hover.CalendarDay__selected_start .renderedDay),
    & :global(.CalendarDay:hover.CalendarDay__selected_span .renderedDay),
    & :global(.CalendarDay:hover.CalendarDay__selected_end .renderedDay) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      background-color: rgb(34, 34, 34);
      color: var(--colorWhite);
      width: 100% !important;
      height: 100% !important;
    }

    & :global(.CalendarDay__selected_span .renderedDay) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: var(--DateRangeController_selectionHeight);
      background-color: rgb(247, 247, 247);
      color: var(--colorBlack);
      transition: all 0.2s ease-out;
    }

    /* Remove default bg-color and use our extra span instead '.renderedDay' */
    & :global(.CalendarDay__blocked_out_of_range .renderedDay),
    & :global(.CalendarDay__blocked_out_of_range:hover .renderedDay) {
      background-color: transparent;
      color: var(--colorGrey300);
      text-decoration: line-through;
      border: 0;
    }
  }
}

.dateInputWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bottomActions {
  display: flex;
  align-items: center;
  margin: 0 20px 20px 30px;
}

.actionsLeft {
  display: flex;
  margin: 0 0 0 auto;
  gap: 20px;
}

.closeDate {
  min-height: 38px;
  height: 38px;
  width: 100px;
  margin: 0 10px 10px auto;
}

.arrowIcon {
  stroke: var(--marketplaceColor);
  fill: var(--marketplaceColor);
}

.areaSearchDropdown {
  position: absolute;
  left: -2px !important;
  top: 69px;
  /* transform: translateX(-50%); */
  border-radius: 25px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowPopup);
  overflow: hidden;
  z-index: 100;
  width: calc(100% + 4px);
  padding: 24px;
  max-width: 400px;
  @media (--viewportLarge) {
    top: 73px;
    left: -22px !important;
  }
  @media (--viewportMedium) {
    min-width: 400px;
  }
}

.countrySearchDropdown {
  @media (--viewportLarge) {
    left: -27px !important;
  }
}

.areaSearchHeading {
  font-size: 14px;
  font-weight: var(--fontWeightMedium);
  line-height: normal;
  padding: 0;
  margin: 0;
  margin-left: 8px;
}

.areaSearchSegment {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;
  justify-content: start;
}

.areaSearchCard {
  padding: 8px;
  width: 135px;
  max-width: 50%;
  border-radius: 16px;
  cursor: pointer;
  &:hover {
    background-color: #ebebeb;
  }
  @media (--viewportMedium) {
    width: 175px;
  }
}

.countryItem {
  padding: 8px;
  display: flex;
  flex-direction: row;
  border-radius: 14px;
  cursor: pointer;
  &:hover {
    background-color: #ebebeb;
  }
}

.areaSearchImgWrapper {
  border-radius: 16px;
  border: 1px solid #dddddd;
  width: 100%;
  height: 75px;
  overflow: hidden;
  @media (--viewportMedium) {
    height: 100px;
  }
}

.areaSearchImg {
  height: 100%;
  width: 100%;
  object-fit: fill;
  background-color: #fbfcfc;
}

.countryImgWrapper {
  width: 40px;
}

.countryImg {
  height: 20px;
  object-fit: cover;
  background-color: transparent;
}

.areaSearchText {
  font-size: 14px;
  margin-top: 10px;
  font-weight: normal;
}

.keywordCancelIcon {
  position: absolute;
  margin-top: 3px;
  right: 24px;
  cursor: pointer;
  top: 50%;
  transform: translateY(-60%);
  @media (--viewportMedium) {
    transform: translateY(0);
    right: 5px;
    top: 50%;
  }
}

.CountryCancelIcon {
  position: absolute;
  top: 50%;
  margin-top: 3px;
  right: 24px;
  transform: translateY(-60%);
  padding-right: 0px;
  cursor: pointer;
  @media (--viewportMedium) {
    padding-right: 15px;
    transform: translateY(0);
    right: 5px;
    top: 50%;
  }
}
